/**
 * Copyright © 2020 Johnson & Johnson
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 * http://www.apache.org/licenses/LICENSE-2.0
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React from "react";
import { Helmet } from "react-helmet";
import Page from "./Page";

export default class IndexPage extends React.Component {
  render() {
    if (typeof window !== "undefined") {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: "https://github.com/facebook/react/issues/10923"
          }}
        />
      );
    }
    return (
      <React.Fragment>
        <Page />
        <Helmet>
          <html className="no-js" lang="fr" />
          <body className="page  fr" />
          <script innerHTML='window.digitalData = window.digitalData || [];
digitalData.push({
   "event": "Page Loaded",
   "page": {
        "analyticsId":"UA-91649088-70",
        "brandName":"Bronchosedal",
        "country":"be",
        "flattened":true,
        "hostname":"www.bronchosedal.be",
        "language":"be",
        "siteTier":"low",
    }
});' />
<script
            innerHTML="(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','globalDataLayer','GTM-PJSTS9Z');"
          />
          <meta charSet="utf-8" />
          <title>Mentions légales | Bronchosedal</title>
          <meta
            name="description"
            content="
    Bronchosedal® - Last van een vervelende hoest? Bronchosedal® brengt verlichting.
"
          />
          <meta
            name="viewport"
            content="width=device-width,initial-scale=1.0,maximum-scale=1.0"
          />
          <link rel="stylesheet" href="/assets/css/screen.css" />
          <script src="/assets/js/vendor/modernizr-2.5.3.min.js" />
<script
            src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js" data-document-language="true" type="text/javascript" charset="UTF-8" data-domain-script="32835d6f-e3c9-48c1-99fd-b5092c5177f7"
          />
           <script
            type="text/javascript"
            innerHTML="function OptanonWrapper() { }"
          />
        </Helmet>
        ;
      </React.Fragment>
    );
  }
}
